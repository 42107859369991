import { Portfolio } from "../components/Portfolio"
import { Header } from "../components/Header"
import { Introduction } from "../components/Introduction"
import { useEffect } from "react"
import { scrollFadeIn, scrollFadeInFast } from "../effects/Observers"
import { Footer } from "../components/Footer";
import { Meetings } from "../components/Meetings"
import axios from 'axios'

export const HomeScreen = () => {

    useEffect(() => {

        scrollFadeIn()
        scrollFadeInFast()

        const endpoint = async () => {

            await axios.get(`https://worker.batataok.com.ar/`
            )
                .then(async (value) => {

                    console.clear();
                    console.log('====================================');
                    console.log(JSON.stringify(value.data));
                    console.log('====================================');


                }).catch((e) => {

                    console.log(JSON.stringify(e));

                })

        }

        endpoint()

    }, [])

    return (

        <div className='home__main-content'>

            <Header />

            <Introduction />

            <Portfolio />

            <Meetings />

            <Footer />

        </div>


    )
}
